.notFoundSection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.cardsWrapperTitle {
  width: 100%;
  padding: 5px 15px;
  background-color: #605959;
  color: white;
  font-size: 20px;
  margin-bottom: 10px;
}

.cardWrapper {
  padding: 20px 10px 10px 10px;
  box-shadow: rgba(140, 152, 164, 0.25) 0px 3px 6px 0px;
  border: 2px solid #F4F2F2;
  border-radius: 15px;
  min-width: 250px;
  max-width: 320px;
}

.cardTitle {
  display: flex;
  width: 100%;
  font-size: 20px;
  overflow-wrap: anywhere;
  margin: 10px 10px 10px 0px;
  justify-content: space-between;
}

.phaseWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summarySection {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.summary {
  height: 95px;
  text-overflow: ellipsis;
  overflow: hidden;
}