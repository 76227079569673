.addChallengeButtonWrapper {
  width: 100%;
  margin: 15px 5px;
  display: flex;
  justify-content: end;
}

.challengeBuilder {
  max-height: 450px;
  width: 648px;
  overflow-y: auto;
}

@media screen and (max-width: 720px) {
  .challengeBuilder {
    width: 90vw;
  }
}